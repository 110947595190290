import React, { useEffect, useRef, useState } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import * as d3 from "d3";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { IAnswer } from "../../survey/survey-questions/answers/answer";
import { IQuestionGroup } from "../../survey/survey-question-groups";
import { IQuestion } from "../../survey/survey-questions/survey-questions";

// type Orientation = "vertical" | "horizontal";

interface BarChartProps {
  // data: { category: string; totalAnswers: number }[];
  // orientation: Orientation;
}

const D3BarChart: React.FC<BarChartProps> = () => {
  const chartRef = useRef<SVGSVGElement | null>(null);
  const questionGroups: IQuestionGroup[] = useSelector(
    (state: RootState) => state.survey.QuestionGroups
  );
  const answers: IAnswer[] = useSelector((state: RootState) => state.survey.Answers);
  const questions: IQuestion[] = useSelector((state: RootState) => state.survey.Questions);

  const countPercent = (qg: IQuestionGroup): number => {
    const question = questions.find((q) => q.questionGroupId === qg.id);
    if (!question) return 0;

    const currentScorePerCategory = answers
      .filter((a) => a.isSelected && a.questionId === question.id)
      .map((a) => a.score)
      .reduce((acc, val) => acc + val, 0);

    const maxScorePerCategory = answers
      .filter((a) => a.questionId === question.id)
      .map((a) => a.score)
      .reduce((max, curr) => Math.max(max, curr), 0);

    return ((currentScorePerCategory / maxScorePerCategory) * 100);
  };

  const data = questionGroups
    .filter((qg) => qg.inLevel === 2)
    .map((qg) => ({
      category: qg.name ?? "No name",
      totalAnswers: countPercent(qg),
    }))
    .sort((a, b) => b.totalAnswers - a.totalAnswers);

  useEffect(() => {
    const svgWidth = chartRef.current?.parentElement?.clientWidth || 800;
    const svgHeight = 400;

    d3.select(chartRef.current).selectAll("*").remove();

    const margin = { top: 30, right: 10, bottom: 120, left: 100 };
    const width = svgWidth - margin.left - margin.right;
    const height = svgHeight - margin.top - margin.bottom;

    const svg = d3
      .select(chartRef.current)
      .attr("width", svgWidth)
      .attr("height", svgHeight)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const x = d3
      .scaleBand()
      .domain(data.map((d) => d.category))
      .range([0, width])
      .padding(0.2);

    const y = d3
      .scaleLinear()
      // .domain([0, d3.max(data, (d) => d.totalAnswers) || 0])
      .domain([0, 100])
      .nice()
      .range([height, 0]);

    const getColor = (value: number): string => {
      if (value >= 75) return "#4caf50";
      if (value >= 50) return "#ffeb3b";
      if (value >= 25) return "#ff9800";
      return "#f44336";
    };

    // const getColor = (value: number): string => {
    //   const colorScale = d3
    //     .scaleLinear<string>()
    //     .domain([0, 100]) 
    //     .range(["#f44336", "#4caf50"]) 
    //     .interpolate(d3.interpolateHcl); 
    
    //   return colorScale(value); 
    // };

    svg
      .append("g")
      .attr("transform", `translate(0,${height})`)
      .call(d3.axisBottom(x).tickSize(0))
      .selectAll("text")
      .attr("transform", "rotate(-45)")
      .style("text-anchor", "end");

    svg.append("g").call(d3.axisLeft(y));

    svg
      .selectAll("rect")
      .data(data)
      .enter()
      .append("rect")
      .attr("x", (d) => x(d.category) || 0)
      .attr("y", (d) => y(d.totalAnswers))
      .attr("width", x.bandwidth())
      .attr("height", (d) => height - y(d.totalAnswers))
      .attr("fill", (d) => getColor(d.totalAnswers));
      
      // svg
      // .selectAll("text.label")
      // .data(data)
      // .enter()
      // .append("text")
      // .attr("class", "label")
      // .attr("x", (d) => (x(d.category) || 0) + x.bandwidth() / 2 + 5) // Shift right by 5px
      // .attr("y", (d) => y(d.totalAnswers) - 10) // Position slightly above the bar
      // .attr("transform", (d) => {
      //   const xPos = (x(d.category) || 0) + x.bandwidth() / 2 + 5; // Include the shift
      //   const yPos = y(d.totalAnswers) - 10;
      //   return `rotate(-45, ${xPos}, ${yPos})`; // Rotate around its position
      // })
      // .attr("text-anchor", "middle") // Center-align the text
      // .text((d) => `${d.totalAnswers.toFixed(1)}%`)
      // .style("fill", "#000") // Optional: Text color
      // .style("font-size", "10px"); // Reduced font size
    



  }, [data]);

  return <svg ref={chartRef} />;
};


const StackedBarChart = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
      <Box
        sx={{
          display: "flex", flexDirection: "column", alignItems: "center",
          overflowX: "auto",
          width: "80vw", // Ensures it spans the full viewport width
          maxWidth: "100%",
        }}
      >
        <Box sx={{ width: '400px' }}>
          <D3BarChart />
        </Box>
      </Box>
    </Box>
  );
};

export default StackedBarChart;

