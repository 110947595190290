

import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, Button, Modal, Typography, Box } from '@mui/material';
import { Colors } from '../../../constants/constants';

// Sample data for the reports
const sampleRows = [
    { id: 1, name: 'Opsti', isChecked: false },
    { id: 2, name: 'KV', isChecked: false },
    { id: 3, name: 'Probava', isChecked: true },
];

// Define the interface for the report table rows
export interface IHealthExamination {
    id: number;
    name: string;
    isChecked: boolean;
}

// Props interface for ReportTable component
interface ReportTableProps {
    data: IHealthExamination[];
}

// Mapping reportName to tests and categories
const reportDetails = {
    Opsti: {
        tests: "Kompletna krvna slika, Analiza urina",
        categories: "Svi korisnici"
    },
    KV: {
        tests: "Masnoce u krvi, EKG, Ultrazvuk srca, Test opterecenja",
        categories: "Muskarci 40 do 49, Muskarci 50+, Zene 50+, Rizik od KVS"
    },
    Probava: {
        tests: "Test krv u stolici, Okultno krvarenje, Kolonoskopija, KKS sa sedimentacijom",
        categories: "Muskarci 50+, Zene 50+"
    }
};

const ReportTable: React.FC<ReportTableProps> = ({ data }) => {
    const [open, setOpen] = useState(false);
    const [selectedReport, setSelectedReport] = useState<string | null>(null);

    const handleOpen = (reportName: string) => {
        setSelectedReport(reportName);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedReport(null);
    };

    return (
        <div style={{ height: '100%', width: '100%' }} >
            <TableContainer style={{ height: '100%', overflow: 'auto' }}>
                <Table size="small" stickyHeader>
                    <TableBody>
                        {data.map((row) => (
                            <TableRow key={row.id} sx={{ backgroundColor: 'white' }}>
                                <TableCell sx={{ fontWeight: 'bold', border: 'none' }}>{row.name}</TableCell>
                                <TableCell sx={{ border: 'none' }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={!row.isChecked}
                                        onClick={() => handleOpen(row.name)}
                                        sx={{
                                            backgroundColor: row.isChecked ? 'lightgreen' : 'lightred',
                                            '&:hover': {
                                                backgroundColor: row.isChecked ? 'lightgreen' : 'lightred',
                                            },
                                        }}
                                    >
                                        Pristupi pregledu
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                >
                    <Paper style={{margin:10, padding:10}}>
                        <Typography id="modal-title" variant="h6" component="h2">
                            Pregled: {selectedReport}
                        </Typography>
                        {selectedReport && selectedReport in reportDetails && (
                            <>
                                <Typography id="modal-description" sx={{ mt: 2 }}>
                                    <strong>Testovi:</strong> {reportDetails[selectedReport as keyof typeof reportDetails].tests}
                                </Typography>
                                {/* <Typography id="modal-categories" sx={{ mt: 2 }}>
                                <strong>Kategorije:</strong> {reportDetails[selectedReport as keyof typeof reportDetails].categories}
                            </Typography> */}
                            </>
                        )}
                        <Button variant="contained" onClick={handleClose} sx={{ mt: 2, backgroundColor: 'lightgreen' }}>
                            Zatvori
                        </Button>
                    </Paper>
                </Modal>
            </TableContainer>

            {/* Modal Component */}

        </div>
    );
};



export default ReportTable;
