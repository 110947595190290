
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

import FormImg from '../../../assets/question-groups/Form.avif'
import './survey-form.css'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import {
    Box, Card, CardContent, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup, TextField, Typography, Checkbox, TextFieldProps, Grid,// Button,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { Colors, Genders, Sizes } from '../../../constants/constants';
import { useEffect, useState } from 'react';
import modalFormSlice, { IModal } from '../../../store/login-modal-form/login-modal-form';
import { LoginModal } from '../../navbar/login/login-modal';
import { UpdateUserData } from '../../../store/login-modal-form/login-modal-form-actions';
import { surveyActions } from '../../../store/survey/survey-slice';

export interface IUser {
    id?: number;
    firstName?: string;
    lastName?: string;
    dateOfBirth?: string;
    gender?: Genders;
    phoneNumber?: string;
    email?: string;
    cardiovascular?: boolean;
    diabetes?: boolean;
    cancer?: boolean;
    cancerType?: string;
    highBloodPressure?: boolean;
    other?: boolean;
    otherConditions?: string;
    mentalIllness?: boolean;
    height?: string;
    weight?: string;
    bmi?: string
}


export interface ISurveyFormProps {

}

export interface IValidationFrom {
    personalData?: {
        name?: string,
        surname?: string,
        dateOfBirth?: string,
        gender?: string, //enum from constants/constants.ts
        phoneNumber?: string,
        height?: string,
        weight?: string,
        bmi?: string
    }
}

export const SurveyForm: React.FunctionComponent<ISurveyFormProps> = props => {
    const browserWidth = useSelector((state: RootState) => state.general.browserWidth) || window.innerWidth
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const userData: IModal = useSelector((state: RootState) => state.loginModal)
    const dispatch: any = useDispatch();

    
    // console.log(userData)
    const initialDate = userData.userData.dateOfBirth ? dayjs(userData.userData.dateOfBirth) : dayjs('1980-01-01');
    const [bmi, setBmi] = useState<string>(''); // State to hold calculated BMI
    const [errors, setErrors] = useState<IValidationFrom>({
        personalData: {
            name: "",
            surname: "",
            dateOfBirth: "",
            // gender: Genders.male, 
            // phoneNumber: "",
            height: "",
            weight: ""
            // bmi: ""
        }
    });

    const validation = (): boolean => {
     
        const user = userData.userData;
        const pData = errors.personalData || {};

        // Validacija imena
        const nameRegex = /^[a-zA-ZČčĆćŠšĐđŽžА-Яа-яЉљЊњЂђЋћЖж]+$/;

        // Validacija imena
        if (!user.firstName) {
            pData.name = 'Ime je obavezno.';
        } else if (user.firstName.length < 2 || user.firstName.length > 30) {
            pData.name = 'Ime mora imati između 2 i 30 karaktera.';
        } else if (!nameRegex.test(user.firstName)) {
            pData.name = 'Ime može sadržati samo slova latinice i ćirilice.';
        } else {
            pData.name = undefined;
        }

        // Validacija prezimena
        if (!user.lastName) {
            pData.surname = 'Prezime je obavezno.';
        } else if (user.lastName.length < 2 || user.lastName.length > 30) {
            pData.surname = 'Prezime mora imati između 2 i 30 karaktera.';
        } else if (!nameRegex.test(user.lastName)) {
            pData.surname = 'Prezime može sadržati samo slova latinice i ćirilice.';
        } else {
            pData.surname = undefined;
        }

        // Validacija datuma rođenja
        const today = new Date();
        const minAge = 18;
        const maxAge = 120;

        if (!user.dateOfBirth) {
            pData.dateOfBirth = 'Datum rođenja je obavezan.';
        } else {
            const birthDate = new Date(user.dateOfBirth);

            if (isNaN(birthDate.getTime())) {
                pData.dateOfBirth = 'Datum rođenja nije validan.';
            } else if (birthDate > today) {
                pData.dateOfBirth = 'Datum rođenja ne može biti u budućnosti.';
            } else {
                const age = today.getFullYear() - birthDate.getFullYear();
                const ageAdjustment = today < new Date(today.getFullYear(), birthDate.getMonth(), birthDate.getDate()) ? 1 : 0;

                const adjustedAge = age - ageAdjustment;

                if (adjustedAge < minAge) {
                    pData.dateOfBirth = `Morate imati najmanje ${minAge} godina.`;
                } else if (adjustedAge > maxAge) {
                    pData.dateOfBirth = `Uneti datum rođenja nije realan (više od ${maxAge} godina).`;
                } else {
                    pData.dateOfBirth = undefined;
                }
            }
        }

        // Validacija pola
        if (!user.gender || (user.gender !== Genders.male && user.gender !== Genders.female)) {
            pData.gender = 'Pol je obavezan.';
        } else {
            pData.gender = undefined;
        }
        // console.log('user.gender:', user.gender);
        // Validacija broja telefona
        // if (!user.phoneNumber) {
        //     pData.phoneNumber = 'Broj telefona je obavezan.';
        // } else if (!/^\d{9,13}$/.test(user.phoneNumber)) {
        //     pData.phoneNumber = 'Broj telefona mora sadržati između 9 i 13 cifara.';
        // } else {
        //     pData.phoneNumber = undefined;
        // }

        // Validacija visine
        if (!user.height) {
            pData.height = 'Visina je obavezna.';
        } else if (!/^\d+$/.test(user.height)) {
            pData.height = 'Visina mora sadržati samo cifre.';
        } else if (Number(user.height) < 50 || Number(user.height) > 250) {
            // pData.height = 'Visina mora biti između 50 i 250 cm.';
        } else {
            pData.height = undefined;
        }

        // Validacija težine
        if (!user.weight) {
            pData.weight = 'Težina je obavezna.';
        } else if (!/^\d+$/.test(user.weight)) {
            pData.weight = 'Težina mora sadržati samo cifre.';
        } else if ( Number(user.weight) > 500) {
            // pData.weight = 'Težina nemoze biti veca od 500 kg.';
        } else {
            pData.weight = undefined;
        }


        setErrors({ ...errors, personalData: pData });

        const hasErrors = Object.values(pData).some((error) => error !== undefined);
        // console.log('hasErrors', hasErrors, errors)
        dispatch(surveyActions.setAllowNext(hasErrors))

        return !hasErrors;
    };



    useEffect(() => {
        validation();
        const timer = setTimeout(() => {
            dispatch(UpdateUserData(fetch, userData.userData));
        }, 2000);

        return () => {
            clearTimeout(timer);
        };
    }, [
        userData.userData.firstName
        , userData.userData.lastName
        , userData.userData.dateOfBirth
        , userData.userData.gender
        , userData.userData.phoneNumber
        , userData.userData.email
        , userData.userData.height
        , userData.userData.weight
        , userData.userData.bmi

    ]);

    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         // console.log('EXE')
    //         // dispatch(InsertUpdateFamilyHistory(fetch, userData.userData));
    //     }, 2000);

    //     return () => {
    //         clearTimeout(timer);
    //     };
    // }, [
    //     userData.userData.cardiovascular
    //     , userData.userData.diabetes
    //     , userData.userData.cancer
    //     , userData.userData.cancerType
    //     , userData.userData.highBloodPressure
    //     , userData.userData.other
    //     , userData.userData.otherConditions
    //     , userData.userData.mentalIllness
    // ]);

    useEffect(() => {
        const height = Number(userData.userData.height);
        const weight = Number(userData.userData.weight);

        if (height > 0 && weight > 0) {
            const heightInMeters: number = height / 100;
            const bmiValue: number = weight / (heightInMeters * heightInMeters);
            setBmi(bmiValue.toFixed(2));
        } else {
            setBmi('');
        }
    }, [userData.userData.height, userData.userData.weight]);


    return (
        <Box sx={{ backgroundColor: 'white'}}>
            <div className={`mx-0 mx-sm-auto mt-0 border-0 d-flex justify-content-center ${browserWidth <= Sizes.BreakPoint ? 'flex-column' : ''}`}>
                <div className=' w-100 d-flex align-items-center' style={{ backgroundColor: 'rgba(219,239,239)' }}>
                    <img
                        src={FormImg}
                        alt="Form Image"
                        className="img-fluid w-100 circular-image px-1"

                    />
                </div>

                <div className="card border-0 w-100" style={{ backgroundColor: Colors.MMYellow2lighter }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                        }}
                    >
                        <Card sx={{ width: '100%', border: 'none', padding: 0, margine: 0, backgroundColor: Colors.MMYellow2lighter, boxShadow: '0 0 0 0px rgba(0,0,0,0.1)', }}>
                            <CardContent sx={{ borderTop: 'none', borderRight: 'none', borderBottom: 'none', borderLeft: 'none', backgroundColor: Colors.MMYellow2lighter }}>
                                <Box sx={{ textAlign: 'center', mb: 2 }}>
                                    <i className="far fa-file-alt fa-4x mb-3 text-primary"></i>
                                    <Typography variant="h6">
                                        <strong>OPŠTI PODACI</strong>
                                    </Typography>
                                </Box>

                                <Divider sx={{ mb: 2 }} />

                                <form className='border-0'>
                                    <TextField
                                        error={errors.personalData?.name != undefined}
                                        helperText={errors.personalData?.name}
                                        onChange={
                                            (e) => dispatch(modalFormSlice.actions.setUserData({
                                                userData: { ...userData.userData, firstName: e.target.value },
                                                IsAuthenticated: userData.IsAuthenticated
                                            }
                                            ))
                                        }
                                        value={userData.userData.firstName}
                                        fullWidth
                                        label="Ime"
                                        variant="outlined"
                                        margin="normal"
                                        placeholder="Unesite ime "
                                        id="fullName"
                                        size="small"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />

                                    <TextField
                                        error={errors.personalData?.surname != undefined}
                                        helperText={errors.personalData?.surname}
                                        onChange={
                                            (e) => dispatch(modalFormSlice.actions.setUserData({
                                                userData: { ...userData.userData, lastName: e.target.value },
                                                IsAuthenticated: userData.IsAuthenticated
                                            }
                                            ))
                                        }
                                        value={userData.userData.lastName}
                                        fullWidth
                                        label="Prezime"
                                        variant="outlined"
                                        margin="normal"
                                        placeholder="Unesite prezime"
                                        id="fullName"
                                        size="small"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            onChange={(date) =>
                                                dispatch(modalFormSlice.actions.setUserData({
                                                    userData: { ...userData.userData, dateOfBirth: date?.format('YYYY-MM-DD') },
                                                    IsAuthenticated: userData.IsAuthenticated
                                                }))
                                            }
                                            label="Datum rođenja"
                                            views={['year', 'month', 'day']}
                                            value={initialDate}
                                            // onChange={(newValue) => setSelectedDate(newValue)}
                                            maxDate={dayjs()}
                                            minDate={dayjs().subtract(120, 'year')}
                                            slotProps={{
                                                textField: {
                                                    fullWidth: true,
                                                    variant: 'outlined',
                                                    margin: 'normal',
                                                    size: 'small',
                                                    InputLabelProps: {
                                                        shrink: true,
                                                    },
                                                }
                                            }}
                                        />
                                    </LocalizationProvider>


                                    <FormControl component="fieldset" margin="normal">
                                        <FormLabel component="legend">Pol</FormLabel>
                                        <RadioGroup

                                            value={userData.userData.gender} row aria-labelledby="gender" name="gender" onChange={(e) =>
                                                dispatch(modalFormSlice.actions.setUserData({
                                                    userData: { ...userData.userData, gender: e.target.value === Genders.male ? Genders.male : Genders.female },
                                                    IsAuthenticated: userData.IsAuthenticated
                                                }))
                                            }>
                                            <FormControlLabel
                                                value={Genders.male}
                                                control={
                                                    <Radio

                                                        sx={{
                                                            color: 'gray',
                                                            '&.Mui-checked': {
                                                                color: 'gray',
                                                            }
                                                        }}
                                                    />
                                                }
                                                label="Muški"
                                            />
                                            <FormControlLabel
                                                value={Genders.female}
                                                control={
                                                    <Radio
                                                        sx={{
                                                            color: 'gray',
                                                            '&.Mui-checked': {
                                                                color: 'gray',
                                                            }
                                                        }}
                                                    />
                                                }
                                                label="Ženski"
                                            />
                                        </RadioGroup>
                                    </FormControl>

                                    {/* <TextField
                                        error={errors.personalData?.phoneNumber != undefined}
                                        helperText={errors.personalData?.phoneNumber}
                                        onChange={(e) =>
                                            dispatch(modalFormSlice.actions.setUserData({
                                                userData: { ...userData.userData, phoneNumber: e.target.value },
                                                IsAuthenticated: userData.IsAuthenticated
                                            }))
                                        }
                                        value={userData.userData.phoneNumber}
                                        fullWidth
                                        label="Kontakt telefon"
                                        type="tel"
                                        variant="outlined"
                                        margin="normal"
                                        placeholder="Unesite kontakt telefon"
                                        id="phone"
                                        size='small'
                                        InputLabelProps={{ shrink: true }}
                                    />
                                    <TextField
                                        disabled
                                        value={userData.userData.email}
                                        fullWidth
                                        label="Email adresa"
                                        type="email"
                                        variant="outlined"
                                        margin="normal"
                                        placeholder="Unesite email adresu"
                                        id="email"
                                        size='small'
                                        InputLabelProps={{ shrink: true }}
                                    /> */}
                                    <Grid container spacing={2} sx={{ py: 0 }}>
                                        <Grid item xs={4}>
                                            <TextField
                                                error={!!errors.personalData?.height}
                                                helperText={errors.personalData?.height}
                                                onChange={(e) => {
                                                    const innerValue = Number(e.target.value)
                                                    if (innerValue > 300) {
                                                        dispatch(modalFormSlice.actions.setUserData({
                                                            userData: { ...userData.userData, height: '250' },
                                                            IsAuthenticated: userData.IsAuthenticated
                                                        }))
                                                    } else {
                                                        dispatch(modalFormSlice.actions.setUserData({
                                                            userData: { ...userData.userData, height: e.target.value },
                                                            IsAuthenticated: userData.IsAuthenticated
                                                        }))
                                                    }
                                                }

                                                }
                                                value={userData.userData.height}
                                                fullWidth
                                                label="Visina (cm)"
                                                type="number"
                                                variant="outlined"
                                                margin="normal"
                                                placeholder="Unesite visinu"
                                                size="small"
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ step: "1", max: "250", min: "0" }} // Adjust for integer values
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                error={!!errors.personalData?.weight}
                                                helperText={errors.personalData?.weight}
                                                onChange={(e) => {
                                                    const innerValue = Number(e.target.value)
                                                    if (innerValue > 500) {
                                                        dispatch(modalFormSlice.actions.setUserData({
                                                            userData: { ...userData.userData, weight: '500' },
                                                            IsAuthenticated: userData.IsAuthenticated
                                                        }))
                                                    } 
                                                    else {
                                                        dispatch(modalFormSlice.actions.setUserData({
                                                            userData: { ...userData.userData, weight: e.target.value },
                                                            IsAuthenticated: userData.IsAuthenticated
                                                        }))
                                                    }

                                                }
                                                }
                                                value={userData.userData.weight}
                                                fullWidth
                                                label="Tezina (kg)"
                                                type="number"
                                                variant="outlined"
                                                margin="normal"
                                                placeholder="Unesite tezinu"
                                                size="small"
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ step: "1", max: "500" }} // Adjust for integer values
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                // error={!!errors.personalData?.bmi}
                                                // helperText={errors.personalData?.bmi}
                                                value={bmi || 0}
                                                fullWidth
                                                label="BMI"
                                                type="number"
                                                variant="outlined"
                                                margin="normal"
                                                placeholder="BMI"
                                                size="small"
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ step: "0.01" }} // Allow two decimal places for BMI
                                            />
                                        </Grid>
                                    </Grid>
                                    <Divider sx={{ my: 2 }} />
                                </form>
                            </CardContent>
                        </Card>
                    </Box>
                </div>
            </div>
        </Box>
    );
}

