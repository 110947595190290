

export enum Colors {
    MMYellow = 'rgba(255,255,255,255)',
    MMYellow1 = 'rgba(241,237,233,255)',
    MMYellow2lighter = 'rgb(253, 251, 248)',
    SuccessColor = 'rgb(245, 255, 230)' //aquamarine
}

export enum Sizes {
    BreakPoint = 997
}

export enum Genders {
    male = 'male',
    female = 'female'
}