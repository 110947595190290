import React, { useEffect, useState } from 'react';
import { Box, Paper, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { IUser } from '../../components/survey/survey-form/survey-form';
import { Colors, Sizes } from '../../constants/constants';
import BarChartComponent from '../../components/medical-checkup/barchart/medical-checkup-barchart';
import ReportDataGrid, { IHealthExamination } from '../../components/medical-checkup/report/report';
// import ReportTable from '../../components/medical-checkup/report/report';
import { IAnswer } from '../../components/survey/survey-questions/answers/answer';
import ReportTable from '../../components/medical-checkup/report/report';
import { GetHealthExaminations } from '../../store/survey/survey-actions';

export interface IMedicalCheckupProp {
    checkupType: 'Opsti' | 'KV' | 'Probava';
}

export const MedicalCheckupPage: React.FunctionComponent<IMedicalCheckupProp> = ({ checkupType }) => {
    const userData: IUser = useSelector((state: RootState) => state.loginModal.userData);

    const answers: IAnswer[] = useSelector((state: RootState) => state.survey.Answers)
    const dispatch: any = useDispatch();
    // const [reportTable, setReportTable] = useState<IHealthExamination[]>([
    //     { id: 1, reportName: 'Opsti', isChecked: false },
    //     { id: 2, reportName: 'KV', isChecked: false },
    //     { id: 3, reportName: 'Probava', isChecked: false },
    // ]);
    const reportTable: IHealthExamination[] = useSelector((state: RootState) => state.survey.HealthExaminations)
    useEffect(() => {
        dispatch(GetHealthExaminations(fetch))
    }, [])
    // console.log(reportTable)

    const reportData = () => {
        console.log(answers)
        return answers;
    };

    // useEffect(() => { setReportTable(reportData()) }, [answers])
    const browserWidth = useSelector((state: RootState) => state.general.browserWidth)
    let boxWidth = {}
    if (browserWidth != undefined && browserWidth > Sizes.BreakPoint) boxWidth = { width: '75%' }
    if (browserWidth != undefined && browserWidth < Sizes.BreakPoint) boxWidth = { width: '100%' }

    return (
        <Box sx={{...boxWidth, my:4}}>
            <Box p={3} flexDirection="column" alignItems="center" sx={{ width: '100%', p: 0 }}>
                <Paper elevation={3} style={{ padding: '10px', maxWidth: '100%', textAlign: 'center', backgroundColor: Colors.MMYellow2lighter }} >
                    <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={12}>
                            <Paper sx={{ height: '100%', weight: '100%', p: 1 }}><Typography variant='h4'>PRIMER IZVESTAJA</Typography></Paper>
                        </Grid>
                    </Grid >
                    <Grid container spacing={2}>
                        {/* User data section in a table */}
                        <Grid item xs={12} sm={6}>
                            <Paper sx={{ p: 1 }}>
                                <Typography variant="h6" gutterBottom>Korisnicke informacije</Typography>
                                <TableContainer>

                                    <Table size="small" sx={{ borderCollapse: 'collapse', height: 'auto' }}>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell sx={{ fontWeight: 'bold', border: 'none' }}>Ime</TableCell>
                                                <TableCell sx={{ border: 'none' }}>{userData.firstName} {userData.lastName}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={{ fontWeight: 'bold', border: 'none' }}>Email</TableCell>
                                                <TableCell sx={{ border: 'none' }}>{userData.email}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={{ fontWeight: 'bold', border: 'none' }}>Telefon</TableCell>
                                                <TableCell sx={{ border: 'none' }}>{userData.phoneNumber}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={{ fontWeight: 'bold', border: 'none' }}>Pol</TableCell>
                                                <TableCell sx={{ border: 'none' }}>{userData.gender}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={{ fontWeight: 'bold', border: 'none' }}>Datum Rodjenja</TableCell>
                                                <TableCell sx={{ border: 'none' }}>{userData.dateOfBirth}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>

                                </TableContainer>
                            </Paper>
                        </Grid>

                        {/* Checkup description section */}
                        <Grid item xs={12} sm={6} display="flex" flexDirection="column" justifyContent="center">
                            <Paper sx={{ p: 1, height: '100%' }}>
                                <Typography variant="h6" gutterBottom>
                                    Sistematski pregledi
                                </Typography>
                                {/* <Typography variant="body1" gutterBottom>{description}</Typography> */}
                                <ReportTable data={reportTable} />
                            </Paper>
                        </Grid>

                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} display="flex" flexDirection="column" justifyContent="center">

                            <Paper sx={{ p: 1, height: '100%', mt: 2 }}>
                                <Typography>MEDICINSKA ISTORIJA PACIJENTA</Typography>
                                <BarChartComponent />

                            </Paper>
                        </Grid>
                    </Grid>


                </Paper>
            </Box>
        </Box>
    );
};
