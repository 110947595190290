import React, { useEffect, useState } from 'react';
import "./survey-question-groups.css";
import { IQuestion, SurveyQuestions } from './survey-questions/survey-questions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import Alergije from '../../assets/question-groups/Alergije.jpg'
import Anestezija from '../../assets/question-groups/Anestezija.jpg'
import Lekovi from '../../assets/question-groups/Lekovi.jpg'
import PrevencijaiScreening from '../../assets/question-groups/prevencijaiscreening.webp'
import SimptomiBolesti from '../../assets/question-groups/simptomiBolesti.jpg'
import Srce from '../../assets/question-groups/Srce.jpg'
import Pluca from '../../assets/question-groups/Pluca.avif'
import Stetoskop from '../../assets/question-groups/Stetoskop.jpg'
import Endokrini from '../../assets/question-groups/Endokrini.jpg'
import NervniPoremecaji from '../../assets/question-groups/NervniPoremecaji.jpg'
import FizickaAktivnost from '../../assets/question-groups/FizickaAktivnost.jpg'
import SocijalniOdnosi from '../../assets/question-groups/SocijalniOdnosi.jpg'
import Promena from '../../assets/question-groups/Promena.avif'
import Emocije from '../../assets/question-groups/EmotionalWellbeing.avif'
import Ishrana from '../../assets/question-groups/Ishrana.avif'
import San from '../../assets/question-groups/San.jpg'
import Drugs from '../../assets/question-groups/Drugs.jpg'
import FamilyHistory from '../../assets/question-groups/FamilyHistory.avif'
import JobHistory from '../../assets/question-groups/JobHistory.avif'
import ProfesionalniRizik from '../../assets/question-groups/riskpro.jpg'
import pip from '../../assets/question-groups/pip.avif'
import ons from '../../assets/question-groups/ons.jpg'
import gi from '../../assets/question-groups/gi.jpg'
import ug from '../../assets/question-groups/ug.jpg'
import aid from '../../assets/question-groups/aid.jpg'
import godinePole from '../../assets/question-groups/godinePol.avif'
import metabolizam from '../../assets/question-groups/metabolizam.webp'
import { Colors } from '../../constants/constants';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, IconButton, Typography } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import ErrorIcon from '@mui/icons-material/Error';
import { useTheme } from '@mui/material/styles';
import surveySlice from '../../store/survey/survey-slice';


// export interface IUserAnswer {
//     id: number,
//     value: string
// }

export interface IQuestionGroup {
    id: number | undefined,
    name: string | undefined,
    inLevel: number | undefined,
    parentId: number | undefined,
    questions: any[] | undefined,
    subgroups: any[] | undefined,
    url: string | undefined,
    orderno: number | null,
    isOpen: boolean,
    isAnswered: boolean
}

export interface IApiResponse<T> {
    Data: T,
    IsSuccess: boolean,
    Message: string,
    StatusCode: number
}

export interface IQuestionGroupProps {
    questionGroups?: IQuestionGroup[],
    questions?: IQuestion[],
    groupName?: string,
    categoryId?: number
}

export const QuestionGroups: React.FunctionComponent<IQuestionGroupProps> = props => {
    const { questionGroups, questions } = props
    const theme = useTheme();
    const dispatch: any = useDispatch();

    const [showProgress, setSP] = useState();

    const hasProblem: IQuestion[] = useSelector((state: RootState) =>
        Array.from(
            new Set(
                state.survey.Questions
                    .filter(x => !x.isSavedInDB)
            )
        )
    );



    type ImageMap = {
        [key: string]: string;
    };

    const imgs: ImageMap = {
        'alergije': Alergije,
        'anestezija': Anestezija,
        'lekovi': Lekovi,
        'prevencija i screening': PrevencijaiScreening,
        'simptomi bolesti': SimptomiBolesti,
        'kardiovaskularno zdravlje': Srce,
        'respiratorno zdravlje': Pluca,
        'ostala medicinska istorija': Stetoskop,
        'hormonski faktori': Endokrini,
        'neurološka oboljenja': NervniPoremecaji,
        'fizicka aktivnost': FizickaAktivnost,
        'socijalni odnosi': SocijalniOdnosi,
        'motivacija - da li ste spremni za promene': Promena,
        'mentalno i emocionalno zdravlje': Emocije,
        'ishrana': Ishrana,
        'spavanje': San,
        'rizicne supstance': Drugs,
        'podkategorija 1': JobHistory,
        'porodicna anamneza': FamilyHistory,
        'profesija': ProfesionalniRizik,
        'pip': pip,
        'otpornost na stres': ons,
        'gastrointestinalno zdravlje': gi,
        'urogenitalni sistem': ug,
        'autoimune bolesti': aid,
        'godine i pol':godinePole,
        'metabolicki faktori': metabolizam
    };

    const Options = (): JSX.Element[] => {
        if (!questionGroups) return [];

        return questionGroups.map((group: IQuestionGroup, i) => {
   
            const imgStyle = {
                width: 60,
                height: 60,
                backgroundImage: `url(${imgs[group.name?.toLowerCase() || 'def']})`,
                backgroundColor: '#f0f0f0',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                borderRadius: '50%',
                mr: 2,
                border: '1px solid lightgrey',
                minWidth: '60px'
            };

            const imgStyle2 = {
                width: 350,
                height: 320,
                backgroundImage: `url(${imgs[group.name?.toLowerCase() || 'def']})`,
                backgroundColor: '#f0f0f0',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                borderRadius: '50%',
                mr: 0,
                border: '1px dotted lightgrey',
                mb: 2
            };

            // Ovde se odredjuje broj odgovorenih pitanja i ukupan broj pitanja po kategorijama
            // toExpand = expandedCategory === i;
            const toExpand = group.isOpen || questionGroups.length === 1;

            const completedGroup = group.isAnswered 

            return (
                <div>
                    <div className={`${questionGroups.length !== 1 && 'image-container'} p-0`} key={i}>

                        <Accordion
                            expanded={toExpand}
                            // onChange={() => dispatch(surveySlice.actions.setOpenQuestionGroup(group))}
                            sx={{ mb: 1, p: 0, backgroundColor: completedGroup ? Colors.SuccessColor : Colors.MMYellow2lighter }} //accord color
                        >
                            <AccordionSummary
                                expandIcon={group.isOpen ? <ExpandLess /> : <ExpandMore />}
                                aria-controls={`panel${i}a-content`}
                                id={`panel${i}a-header`}

                            onClick={() => dispatch(surveySlice.actions.setOpenQuestionGroup({...group, isOpen: !group.isOpen}))}
                            >
                                <div className={`ThisShouldDissapearWhenAccordionOpensDetails ${toExpand ? 'hidden' : 'fade-out'}`}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                        <Box sx={imgStyle} />
                                        <Box sx={{ flexGrow: 0, }}>
                                            <Typography sx={{ fontSize: '13px' }}
                                            >{questionGroups.length !== 1 && group.name?.toUpperCase()}</Typography>
                                            <Typography variant="body2" sx={{ fontSize: '13px' }}
                                            >
                                                {questions?.filter(x => x.isAnswered && x.questionGroupId === group.id).length}/{questions?.filter(x => x.questionGroupId === group.id).length}
                                                {(
                                                    hasProblem.length > 0 &&
                                                    hasProblem.findIndex(x => x.questionGroupId != group.id)

                                                ) && <ErrorIcon sx={{ color: theme.palette.error.light }} />}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </div>
                            </AccordionSummary>

                            <AccordionDetails sx={{ p: 0 }}>
                                <Box>
                                    <Typography variant="h4" sx={{ textAlign: 'center', mb: 2 }}>
                                        {group.name?.toUpperCase()} 
                                        {/* {group.isOpen ? "true" : "false"} */}
                                    </Typography>
                                </Box>
                                <Grid item xs={12} md={4} sx={{ width: '100%', display: 'flex', justifyContent: 'center', borderBottom: '1px dotted lightgrey' }}>
                                    <Box sx={imgStyle2} />
                                </Grid>

                                <SurveyQuestions questionsFiltered={questions ? questions.filter(x => x.questionGroupId === group.id) : []}
                                    completedGroup={completedGroup} />
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 0 }}>
                                    <IconButton onClick={() => dispatch(surveySlice.actions.setOpenQuestionGroup({...group, isOpen: false}))}>
                                        {questionGroups.length !== 1 && <Typography variant="body2">ZATVORI</Typography>}
                                    </IconButton>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </div>
            );
        });
    };


    return (
        <>
            {/* <Paper>{groupName}</Paper> */}
            <div >
                {/* <h3 className='text-center' >{groupName}</h3> */}

                {Options()}
            </div>
        </>

    );

};
